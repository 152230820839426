<template>
<div>
    <b-row>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de documentación 45001</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="4" class="my-2">
                            <b-button block :to="{name: 'Informes 45001'}" size="md" variant="custom">
                                <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br>Volver
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-filter fa-md"></i> <span class="h5"> Filtrar por: </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="6">
                            <b-form-group label="Ejercicio / Año:" class="mb-2">
                                <v-select taggable @input="filtrarInformes('ejercicio')" :reduce="comboEjercicio =>comboEjercicio.ejercicio" label="descripcion" placeholder="Seleccione una opción" v-model="datosFiltro[0].ejercicio" :options="comboEjercicio">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label="Tipo:" class="mb-2">
                                <v-select @input="filtrarInformes('tipo')" :reduce="comboTipo =>comboTipo.tipo" label="descripcion" placeholder="Seleccione una opción" v-model="datosFiltro[0].tipo" :options="comboTipo">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label="Proyecto:" class="mb-2">
                                <v-select @input="filtrarInformes('idProyecto')" :reduce="comboProyectos =>comboProyectos.idProyecto" label="nombre" placeholder="Seleccione una opción" v-model="datosFiltro[0].idProyecto" :options="comboProyectos">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Lista de Proyectos'}"> Registra aquí</router-link></span>
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label="Tiene documento" class="mb-2">
                                <v-select @input="filtrarInformes('hasDocumento')" :reduce="comboTieneDocumento =>comboTieneDocumento.idTieneDocumento" label="descripcion" placeholder="Seleccione una opción" v-model="datosFiltro[0].hasDocumento" :options="comboTieneDocumento">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Informes registrados</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaDocumentacion" :fields="campoDocumentacion" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(opciones)="param">
                                    <b-button v-if="param.item.urlArchivo != ''" @click="descargarDocumento(param.item.urlArchivo)" class="mr-1 mb-1 " variant="blue" size="sm" v-c-tooltip="'Descargar'">
                                        <i class="fas fa-download my-0 mx-0"></i>
                                    </b-button>
                                </template>
                                <template #cell(tipo)="param">
                                    <span v-if="param.value == 1">Documento SST</span>
                                    <span v-else-if="param.value == 2">Comité</span>
                                    <span v-else-if="param.value == 3">Programas, planes e IPERC</span>
                                    <span v-else-if="param.value == 4">Procedimientos y PETS</span>
                                    <span v-else-if="param.value == 5">Formatos de seguridad</span>
                                    <span v-else-if="param.value == 6">Siniestralidad</span>
                                    <span v-else-if="param.value == 7">Salud ocupacional</span>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
export default {
    data() {
        return {
            modoFiltro: true,
            datosSession: {
                idCliente: '',
            },
            datosFiltro: [{}],

            totalRows: 1,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            campoDocumentacion: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "ejercicio",
                    label: "Ejercicio/Año",
                    class: "text-center",
                },
                {
                    key: "proyecto",
                    label: "Proyecto",
                    class: "text-center",
                },
                {
                    key: "codigo",
                    label: "Código",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Documento",
                    class: "text-center",
                },
                {
                    key: "fechaPublicacion",
                    label: "Fecha publicación",
                    class: "text-center",
                },
                {
                    key: "responsable",
                    label: "Responsable",
                    class: "text-center",
                },
                {
                    key: "tipo",
                    label: "Tipo",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaDocumentacion: [],
            listaDocumentacionOriginal: [],

            comboEjercicio: [],
            comboTipo: [{
                tipo: 0,
                descripcion: 'Mostrar todos'
            }, {
                tipo: 1,
                descripcion: 'Documentos de SST'
            }, {
                tipo: 2,
                descripcion: 'Comité'
            }, {
                tipo: 3,
                descripcion: 'Programas, planes, IPERC'
            }, {
                tipo: 4,
                descripcion: 'Procedimientos y PETS'
            }, {
                tipo: 5,
                descripcion: 'Formatos de seguridad'
            }, {
                tipo: 6,
                descripcion: 'Siniestralidad'
            }, {
                tipo: 7,
                descripcion: 'Salud ocupacional'
            }],
            comboTieneDocumento: [{
                idTieneDocumento: 0,
                descripcion: 'Mostrar todos'
            }, {
                idTieneDocumento: 1,
                descripcion: 'SI'
            }, {
                idTieneDocumento: 2,
                descripcion: 'NO'
            }],
            comboProyectos: [],

        }

    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        descargarDocumento(url) {
            window.open(url)
        },
        llenarComboEjercicio() {
            this.comboEjercicio.push({
                ejercicio: 0,
                descripcion: 'Mostrar todos',
            })

            let anio = parseInt(moment().format('YYYY'));
            for (let i = anio - 10; i < anio + 10; i++)
                this.comboEjercicio.push({
                    ejercicio: i,
                    descripcion: i
                })
        },

        filtrarInformes(item) {
            //limpio si es mostrar todos
            if (this.datosFiltro[0][item] == 0) {
                this.$delete(this.datosFiltro[0], item)
                this.listaDocumentacion = this.listaDocumentacionOriginal
            }
            //si no es mostrar todos
            let me = this;
            me.modoFiltro = true;
            me.listaDocumentacion = me.listaDocumentacionOriginal.filter(function (item) {
                for (var key in me.datosFiltro[0]) {
                    if (item[key] === undefined || item[key] != me.datosFiltro[0][key])
                        return false;
                }
                return true;
            });
            me.totalRows = me.listaDocumentacion.length;
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        eliminarInforme() {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el informe?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {})
        },
        listarDocumentacion() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-busqueda-documentos-sst", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaDocumentacionOriginal = response.data;
                    me.listaDocumentacion = response.data;
                    me.totalRows = me.listaDocumentacion.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });

        },
        listarProyectos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-proyectos", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboProyectos.push({
                        idProyecto: 0,
                        nombre: 'Mostrar todos'
                    })
                    for (let k in response.data) {
                        me.comboProyectos.push({
                            idProyecto: response.data[k].idProyecto,
                            nombre: response.data[k].nombre,
                        })
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarDocumentacion();
            this.llenarComboEjercicio();
            this.listarProyectos();
        }
    }

}
</script>
